import { Documento, Empresa, GrupoDocumento } from ".";

export class EmpresaDocumento implements Shared.IEntity{
    
    id: number = 0;
    documentoId: number = 0;
    documento: Documento = new Documento();
    empresaId: number = 0;
    empresa!: Empresa;

    constructor(model?: EmpresaDocumento){
        
        if(!model)
            return;

        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
    }
}