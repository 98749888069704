







































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { EmpresaService, MotoristaService, TipoDocumentoService, VeiculoService } from '@/core/services/geral';
import { PageBase } from '@/core/models/shared';
import { ArquivoService } from '@/core/services/shared';
import { Documento, MotoristaDocumento, TipoDocumento, VeiculoDocumento } from '@/core/models/geral';
import { DocumentoService } from '@/core/services/geral/DocumentoService';
import { PortoService, ReceptorService, TransportadoraService } from '@/core/services/residuo';
import { PortoDocumento, ReceptorDocumento, TransportadoraDocumento } from '@/core/models/residuo';
import { EmpresaDocumento } from '@/core/models/geral/EmpresaDocumento';

@Component
export default class ListaDocumento extends PageBase {
  search: any = '';
  loading: boolean = false;
  loadingDownload: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Identificação', value: 'identificacao' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Protocolo Ext. Validade', value: 'protocoloValidade' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Arquivo', value: 'arquivo.nome' },
    { text: 'Validade', value: 'dataValidade', type:'date' }
  ];
  item = new Documento();
  service = new DocumentoService();
  sheet: boolean = false;
  apenasVinculadoGrupo: boolean = false;
  filtro: any = {
    identificacao: null,
    descricao: null,
    tipoId: null,
    protocoloValidade: null,
    validadeInicial: null,
    validadeFinal: null,
    usuarioId: null
  }

  tipos: TipoDocumento[] = [];

  dialogDocumento: boolean = false;
  documento: any = {};
  serviceDocumento: any = {};
  selectDialog: boolean = false;
  selectDocumento: number = 0;

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){
    
    if(isFiltro == true){
      this.options.page = 1;
      this.sheet = false;
      isFiltro = false;

      if(this.apenasVinculadoGrupo){
        this.filtro.usuarioId = this.app.usuarioId;
      }
      else{
        this.filtro.usuarioId = null;
      }
    }
    
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;    

    this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (
      this.loading = false
    ));
  }

  mounted(){
    this.HeaderCliente();
    new TipoDocumentoService().ListarTudo().then(
      res => {
        this.tipos = res.data.items;
      }
    )
  }

  HeaderCliente(){    
    if( !(this.app.role == 'Admin' || this.app.role == 'Arquivista' || this.app.role == 'ResiduoArquivista') ){
      this.headers.splice(0,1);
    }   
  }

  AbrirCadastroDocumento(){
    switch(this.selectDocumento){
      case 0:
        this.item = new Documento();
        this.item.empresaId = this.app.empresaId;
        this.service = new DocumentoService();
        this.dialogCadastro = true;
      break;
      case 1:
        this.documento = new EmpresaDocumento();
        this.documento.empresaId = this.app.empresaId;
        this.documento.documento.empresaId = this.app.empresaId;
        this.serviceDocumento = new EmpresaService();
        this.dialogDocumento = true;
      break;
      case 2:
        this.documento = new ReceptorDocumento();
        this.documento.documento.empresaId = this.app.empresaId;
        this.serviceDocumento = new ReceptorService();
        this.dialogDocumento = true;
      break;
      case 3:
        this.documento = new MotoristaDocumento();
        this.documento.documento.empresaId = this.app.empresaId;
        this.serviceDocumento = new MotoristaService();
        this.dialogDocumento = true;
      break;
      case 4:
        this.documento = new VeiculoDocumento();
        this.documento.documento.empresaId = this.app.empresaId;
        this.serviceDocumento = new VeiculoService();
        this.dialogDocumento = true;
      break;
      case 5:
        this.documento = new TransportadoraDocumento();
        this.documento.documento.empresaId = this.app.empresaId;
        this.serviceDocumento = new TransportadoraService();
        this.dialogDocumento = true;
      break;
      case 6:
        this.documento = new PortoDocumento();
        this.documento.documento.empresaId = this.app.empresaId;
        this.serviceDocumento = new PortoService();
        this.dialogDocumento = true;
      break;
    }
    this.selectDialog = false;
  }

  AbrirDialogCadastro(item?: Documento){
    if(item){
      this.service.ObterPorId(item.id, "Tipo, Grupos.Grupo, Grupos.Grupo.Usuarios").then(
        res=>{
          this.item = new Documento(res.data);
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } 
          else {
            if(err.response.status == 403){
              this.$swal("Aviso", err.response.data, "warning");
            }else{
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        }
      )
    }
    else{
      this.$swal("Aviso", "Não é possível criar um documento sem vínculo", "warning");
    }
  }

  Excluir(item: Documento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 400){
              this.$swal("Aviso", err.response.data, "warning");                
            }
            else if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
      )},
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then((result) => {
      if(result.value) {
        this.$swal("Aviso", result.value, "success");
        this.Atualizar();
      }
  })
  }

  AbrirLink(link: string){
    if(!!link)
      window.open(link,"_blank");
  }

  Download(item: Documento) {
    this.loadingDownload = true;
    this.service.Download(item.id).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], item.arquivo!.nome);

      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingDownload = false;
    });
  }
}

